import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en },
    current: 'en'
  },
  theme: {
    themes: {
      light: {
        primary: '#0D287D',
        secondary: '#d2d2d2',
        accent: '#4C558E',
        danger: '#cc252b',
        success: '#6DC286'
      }
    }
  }
})
