/* eslint-disable */
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import route from '@vue/cli-plugin-router'

const $http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  withCredentials: false,
  crossDomain: false,
  params: {}
})

$http.interceptors.request.use(request => {
  request.headers['Accept-Language'] = document.documentElement.lang
  request.headers.Accept = 'application/json'
  if (request.url.includes('token')) {
    request.data.grant_type = process.env.VUE_APP_GRANT_TYPE
    request.data.client_id = Number(process.env.VUE_APP_GRANT_CLIENT_ID)
    request.data.client_secret = process.env.VUE_APP_GRANT_CLIENT_SECRET
  } else {
    request.headers.Authorization = `${store.getters.GET_OAUTH.token_type} ${store.getters.GET_OAUTH.access_token}`
  }
  return request
})

$http.interceptors.response.use(response => {
  // eslint-disable-next-line no-prototype-builtins
  if (response.data.hasOwnProperty('access_token')) {
    store.dispatch('setOAuth', response.data).catch()
    store.dispatch('setAlert', { message: 'You are successfully logged in', show: true, type: 'success' }).then()
    router.push({ name: 'dashboard.main' })
      .then(() => {
        setTimeout(() => {
          store.dispatch('setAlert', false)
        }, 5000)
      })
  }
  // eslint-disable-next-line no-prototype-builtins
  if (response.data.hasOwnProperty('message')) {
    store.dispatch('setAlert', { show: true, type: 'success', message: response.data.message })
  }

  return response
}, error => {
  switch (error.response.status) {
    case 400:
      store.dispatch('setAlert', { show: true, type: 'error', message: error.response.data.message + ' Cannot find user with these credentials' })
        .then(() => {
          if (!route.name.includes('login')) {
            router.push({ name: 'login' }).catch(error => {})
          }
        })
      break
    case 401:
      store.dispatch('setAlert', { show: true, type: 'error', message: 'Please, authenticate yourself' })
        .then(() => {
          if (!route.name.includes('login')) {
            router.push({ name: 'login' }).catch(error => {})
          }
        })
      break
    case 403:
      store.dispatch('setAlert', { show: true, type: 'error', message: error.response.data.error })
      break
    case 500:
      store.dispatch('setAlert', { show: true, type: 'error', message: error.response.data.error }).then()
      break
    default:
      break
  }
})

const HttpService = {
  install (Vue) {
    Vue.prototype.$http = $http
  }
}

export default (HttpService)
