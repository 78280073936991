import { extend, localize, setInteractionMode } from 'vee-validate'
import { email, required, image, min, confirmed, max, regex } from 'vee-validate/dist/rules'

import en from 'vee-validate/dist/locale/en.json'

setInteractionMode('lazy')

extend('required', required)
extend('email', email)
extend('image', image)
extend('min', min)
extend('max', max)
extend('regex', {
  ...regex,
  message: 'This URL is not valid. Please, use valid URL'
})
extend('confirmed', confirmed)
extend('max_files', (value, length) => {
  if (value.length <= length) {
    return true
  }
  return `You try to load more than ${length} files`
})

localize('en', en)
