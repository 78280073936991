<template>
  <transition enter-active-class="animate__animated fadeInUp"
              leave-active-class="animate__animated fadeOutDown">
    <v-overlay v-model="loading"
               opacity="1"
               color="secondary" class="d-flex align-center justify-center">
    </v-overlay>
  </transition>
</template>

<script>
export default {
  name: 'StarboxxLoadingOverlayComponent',
  computed: {
    loading () {
      return this.$store.getters.GET_OVERLAY
    }
  }
}
</script>

<style scoped>

</style>
