export default {
  SET_ROUTE (state, route) {
    state.route = route
  },
  SET_DRAWER (state, drawer) {
    state.drawer = drawer
  },
  SET_ROLE (state, role) {
    state.role = role
  },
  SET_ALERT (state, alert) {
    state.alert = alert
  },
  SET_USER (state, user) {
    state.user = user
  },
  SET_DIALOG (state, dialog) {
    state.dialog = dialog
  },
  SET_WIDTH (state, width) {
    state.width = width
  },
  SET_POST (state, post) {
    state.post = post
  },
  SET_CATEGORY (state, category) {
    state.category = category
  },
  SET_OVERLAY (state, overlay) {
    state.overlay = overlay
  },
  SET_OAUTH (state, oauth) {
    state.oauth = oauth
  },
  SET_CONFIRMATION (state, confirmation) {
    state.confirmation = confirmation
  }
}
