<template>
  <v-snackbar tile
              :width="$vuetify.breakpoint.width / 3"
              color="primary"
              timeout="-1" top :multi-line="true" v-model="show">
    <v-card flat tile color="transparent">
      <v-card-text>
        {{ action.message }}
      </v-card-text>
    </v-card>
    <template v-slot:action>
      <v-spacer></v-spacer>
      <v-btn tile :loading="loading"
             text
             dark
             @click="confirmAction">{{ action.name.replace('-', ' ') }}</v-btn>
      <v-btn tile class="ml-3"
             text
             dark
             @click="closeConfirmation">Cancel</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'StarboxxConfirmationActionComponent',
  props: {
    loading: Boolean,
    action: Object
  },
  methods: {
    closeConfirmation () {
      this.$store.dispatch('setConfirmation', false)
    },
    confirmAction () {
      this.$root.$emit(this.action.name, this.action.data)
      this.closeConfirmation()
    }
  },
  computed: {
    show () {
      return this.$store.getters.GET_CONFIRMATION
    }
  }
}
</script>

<style scoped>

</style>
