<template>
  <v-app>
    <starboxx-alert-component/>
    <v-app-bar
      :height="$vuetify.breakpoint.height / 12"
      app
      color="secondary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          role="button"
          alt="Starboxx Logo"
          class="shrink"
          src="@/assets/logo.png"
          transition="scale-transition"
          @click="$router.push({ name: 'dashboard.main' })"
          :max-width="$vuetify.breakpoint.mdAndDown ? '50' : '120'"
        />
      </div>
    </v-app-bar>
    <v-main>
      <v-container class="pa-0 ma-0" fluid>
        <router-view/>
      </v-container>
    </v-main>
    <v-app-bar
      outlined
      :height="$vuetify.breakpoint.height / 12"
      color="secondary"
      style="z-index: 1"
    >
      <v-row class="d-flex align-center justify-space-between">
        <v-col class="d-flex align-center" cols="10" md="6" :style="`font-size: ${ $vuetify.breakpoint.mdAndDown ? '.7rem' : '' }`">
          <v-icon small>mdi-copyright</v-icon>
          <small class="ml-1">{{ new Date().getFullYear() }}</small>
          <small class="ml-1">Hergestellt von der <a class="accent--text font-weight-bold"
                                                     style="text-decoration: none"
                                                     href="https://northitgroup.com"
                                                     target="_blank">North IT Group</a>. Alle Rechte vorbehalten</small>
        </v-col>
      </v-row>
    </v-app-bar>
    <starboxx-overlay-component/>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      loading: false
    }
  },
  methods: {
    submit () {
      this.loading = 'danger'
      this.$http.post('api/v1/subscribers', this.form)
        .then(response => {
          this.$store.dispatch('setAlert', { show: true, type: 'success', message: response.data.message })
          this.form.email = null
          this.$refs.observer.reset()
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  computed: {
    show: {
      get () {
        return this.$store.getters.GET_DRAWER
      },
      set (value) {
        this.$store.dispatch('setDrawer', value)
      }
    }
  }
}
</script>
