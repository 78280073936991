<template>
  <v-row class="ma-0" :style="`min-height: ${$vuetify.breakpoint.height - ($vuetify.breakpoint.height / 12)}px`">
    <v-col cols="12" class="d-flex"
           :style="`position: relative; padding: ${ padding }`">
      <template v-if="$vuetify.breakpoint.lgAndUp">
        <div style="position: absolute; left: 0; top: 0; width: 100%">
          <v-navigation-drawer
            :style="`z-index: 1;top: ${$vuetify.breakpoint.height / 12}px`"
            :height="$vuetify.breakpoint.height - ($vuetify.breakpoint.height / 12)"
            :width="210"
            color="secondary"
            :mini-variant="$vuetify.breakpoint.mobile"
            fixed
            dark
          >
            <v-list
              dense
              nav
              tile
              class="py-0"
            >
              <v-list-item two-line class="px-0">
                <v-list-item-avatar color="accent">
                  <v-icon>mdi-account-circle</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="primary--text">{{ user.name }}</v-list-item-title>
                  <v-list-item-subtitle class="primary--text">{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-divider class="accent"></v-divider>

              <v-list-item-group>
                <template v-for="(item, i) in menu">
                  <template v-if="item.role && item.role.includes(user.role.role)">
                    <v-list-item  :key="i"
                                  @click="$router.push({name: item.route}).catch( error => {})">
                      <v-list-item-icon class="mr-4">
                        <v-icon color="accent">{{ item.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="accent--text" v-text="item.content"></v-list-item-content>
                    </v-list-item>
                  </template>
                </template>
                <v-divider class="accent"></v-divider>
                <v-list-item @click="logout">
                  <v-list-item-icon class="mr-4">
                    <v-icon color="accent">mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="accent--text">Logout</v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
        </div>
      </template>
      <template v-else>
        <div :style="`min-width: 4%; max-width: 10%; position: fixed; left: 0; top: ${($vuetify.breakpoint.height / 12)}px;min-height: ${$vuetify.breakpoint.height - ($vuetify.breakpoint.height / 12)}px`" class="primary">
          <v-list
            max-width="100%"
            color="transparent"
            dark
            dense
            nav
            tile
            class="py-0"
          >
            <v-list-item two-line class="px-0">
              <v-list-item-avatar size="20" color="secondary">
                <v-icon small>mdi-account-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content v-if="$vuetify.breakpoint.lgAndUp">
                <v-list-item-title>{{ user.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item-group>
              <template v-for="(item, i) in menu">
                <template v-if="item.role && item.role.includes(user.role.role)">
                  <v-list-item class="pa-0"  :key="i"
                               @click="$router.push({name: item.route}).catch( error => {})">
                    <v-list-item-icon class="d-flex justify-center">
                      <v-icon size="20">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </template>
              </template>
              <v-divider></v-divider>
              <v-list-item class="pa-0" @click="logout">
                <v-list-item-icon class="d-flex justify-center">
                  <v-icon size="20">mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content v-if="$vuetify.breakpoint.lgAndUp">Logout</v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </template>
      <div style="width: 100%">
        <transition mode="out-in"
                    enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__fadeOut">
          <router-view/>
        </transition>
      </div>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'Dashboard',
  data () {
    return {
      opened: false,
      menu: [
        {
          icon: 'mdi-post',
          content: 'Pages',
          route: 'dashboard.pages',
          role: ['manager', 'admin']
        },
        {
          icon: 'mdi-account-group',
          content: 'Users',
          route: 'dashboard.users',
          role: ['admin']
        },
        {
          icon: 'mdi-menu',
          content: 'Menu',
          route: 'dashboard.menu',
          role: ['manager', 'admin']
        }
      ]
    }
  },
  beforeMount () {
    this.me()
  },
  methods: {
    me () {
      this.$http.get('api/auth/me')
        .then((response) => {
          this.$store.dispatch('setUser', response.data.me)
        })
    },
    logout () {
      this.$http.post('api/auth/logout')
        .then(response => {
          this.$store.dispatch('setOAuth', {})
          this.$store.dispatch('setAlert', { show: true, type: 'success', message: response.data.message })
          this.$router.push({ name: 'login' }).then(() => {
            setTimeout(() => {
              this.$store.dispatch('setAlert', false)
            }, 5000)
          })
        })
    }
  },
  computed: {
    user () {
      return this.$store.getters.GET_USER
    },
    padding () {
      const desktop = '20px 20px 0 275px'
      const mobile = '20px 20px 20px 10.1%'
      return this.$vuetify.breakpoint.lgAndUp ? desktop : mobile
    }
  }
}
</script>

<style scoped>

</style>
