import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/views/dashboard/Dashboard'
import store from '@/store'
import middlewarePipeline from '@/router/middlewarePipeline'
import auth from '@/router/middlewares/auth'
import loggedIn from '@/router/middlewares/loggedIn'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      middleware: [
        loggedIn
      ]
    }
  },
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      middleware: [
        auth
      ]
    },
    children: [
      {
        path: '',
        component: () => import('../views/dashboard/Main.vue'),
        name: 'dashboard.main',
        redirect: '/dashboard/pages',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/pages',
        component: () => import('../views/dashboard/pages/Pages.vue'),
        name: 'dashboard.pages',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/pages/create',
        component: () => import('../views/dashboard/pages/Create.vue'),
        name: 'dashboard.pages.create',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/pages/:id/edit',
        component: () => import('../views/dashboard/pages/Edit.vue'),
        name: 'dashboard.pages.edit',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/pages/:id/show',
        component: () => import('../views/dashboard/pages/Show.vue'),
        name: 'dashboard.pages.show',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/users',
        component: () => import('../views/dashboard/users/Users.vue'),
        name: 'dashboard.users',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/dashboard/menu',
        component: () => import('../views/dashboard/menu/Menu.vue'),
        name: 'dashboard.menu',
        meta: {
          middleware: [
            auth
          ]
        }
      }
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('setOverlay', true)
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})
router.afterEach((to, from) => {
  setTimeout(() => {
    store.dispatch('setOverlay', false)
  }, 1500)
})

export default router
