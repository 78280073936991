export default {
  route: false,
  drawer: false,
  role: null,
  alert: null,
  user: null,
  dialog: null,
  width: 270,
  post: null,
  category: null,
  overlay: false,
  oauth: {},
  confirmation: false
}
