<template>
  <div style="position: sticky; top: 170px" class="pr-8 pl-4">
    <p class="text-left">
      <span class="font-weight-bold d-block text-h6">Introduction</span>
      <span class="d-block my-2">The Open Graph protocol enables any web page to become a rich object in a social graph. For instance, this is used on Facebook to allow any web page to have the same functionality as any other object on Facebook.</span>
      <span class="d-block my-2">While many different technologies and schemas exist and could be combined together, there isn't a single technology which provides enough information to richly represent any web page within the social graph. The Open Graph protocol builds on these existing technologies and gives developers one thing to implement. Developer simplicity is a key goal of the Open Graph protocol which has informed many of the technical design decisions.</span>
    </p>
    <v-divider class="my-2"></v-divider>
    <div class="text-left">
      <span class="font-weight-bold d-block text-h6">Basic Metadata</span>
      <span class="d-block my-2">To turn your web pages into graph objects, you need to add basic metadata to your page. We've based the initial version of the protocol on RDFa which means that you'll place additional <code>&lt;meta&gt;</code> tags in the <code>&lt;head&gt;</code> of your web page. The four required properties for every page are:</span>
      <div class="d-block my-2">
        <ul>
          <li><span class="success--text">og:title</span> &mdash; The title of your object as it should appear within the graph, e.g., "The Rock".</li>
          <li><span class="success--text">og:description</span> &mdash; A one to two sentence description of your object.</li>
          <li><span class="success--text">og:type</span> &mdash; The type of your object, e.g., "video.movie". Depending on the type you specify, other properties may also be required. More info see <a href="https://ogp.me/#types" target="_blank">here</a></li>
          <li><span class="success--text">og:image</span> &mdash; An image URL which should represent your object within the graph.</li>
          <li><span class="success--text">og:url</span> &mdash; The canonical URL of your object that will be used as its permanent ID in the graph, e.g., "https://www.imdb.com/title/tt0117500/".</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StarboxxTipComponent'
}
</script>

<style scoped>

</style>
