
export default {
  setRoute ({ commit }, data) {
    commit('SET_ROUTE', data)
  },
  setDrawer ({ commit }, data) {
    commit('SET_DRAWER', data)
  },
  setRole ({ commit }, data) {
    commit('SET_ROLE', data)
  },
  setAlert ({ commit }, data) {
    commit('SET_ALERT', data)
  },
  setUser ({ commit }, data) {
    commit('SET_USER', data)
  },
  setWidth ({ commit }, data) {
    commit('SET_WIDTH', data)
  },
  setDialog ({ commit }, data) {
    commit('SET_DIALOG', data)
  },
  logout ({ commit }) {
    commit('SET_USER', null)
  },
  setPost ({ commit }, data) {
    commit('SET_POST', data)
  },
  setCategory ({ commit }, data) {
    commit('SET_CATEGORY', data)
  },
  setOverlay ({ commit }, data) {
    commit('SET_OVERLAY', data)
  },
  setOAuth ({ commit }, data) {
    commit('SET_OAUTH', data)
  },
  setConfirmation ({ commit }, data) {
    commit('SET_CONFIRMATION', data)
  }
}
