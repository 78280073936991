import Vue from 'vue'
import * as truncate from 'truncate-html'
Vue.filter('undefined', (value) => {
  return value || '<span>&mdash;</span>'
})

Vue.filter('truncateHtml', (value, size) => {
  return truncate.default(value, size)
})
Vue.filter('boolean', (value) => {
  return value ? 'Yes' : 'No'
})

Vue.filter('capitalizeFirstLetter', (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
})
