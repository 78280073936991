export default {
  GET_DRAWER (state) {
    return state.drawer
  },
  GET_ALERT (state) {
    return state.alert
  },
  GET_USER (state) {
    return state.user
  },
  GET_DIALOG (state) {
    return state.dialog
  },
  GET_WIDTH (state) {
    return state.width
  },
  GET_POST (state) {
    return state.post
  },
  GET_CATEGORY (state) {
    return state.category
  },
  GET_OVERLAY (state) {
    return state.overlay
  },
  GET_OAUTH (state) {
    return state.oauth
  },
  GET_CONFIRMATION (state) {
    return state.confirmation
  }
}
