import Vue from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import StarboxxAlertComponent from '@/components/StarboxxAlertComponent'
import StarboxxLoadingOverlayComponent from '@/components/StarboxxLoadingOverlayComponent'
import StarboxxTipComponent from '@/components/StarboxxTipComponent'
import StarboxxActionComponent from '@/components/StarboxxConfirmationActionComponent'

Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)
Vue.component('starboxx-alert-component', StarboxxAlertComponent)
Vue.component('starboxx-overlay-component', StarboxxLoadingOverlayComponent)
Vue.component('starboxx-tip-component', StarboxxTipComponent)
Vue.component('starboxx-confirmation', StarboxxActionComponent)
